<template>
  <v-container style="width: 1000px; margin: auto;">
    <v-row>
      <v-col cols="4">
        <SimpleCard/>
      </v-col>
      <v-col cols="4">
        <SpecialCard/>
      </v-col>
      <v-col cols="4">
        <Manufacture/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SimpleCard from './components/simpleCard/Card.vue'
import SpecialCard from './components/specialCard/Card.vue'
import Manufacture from './components/manufacture/Card.vue'
export default {
  components: {
    SimpleCard,
    SpecialCard,
    Manufacture,
  }
}
</script>
