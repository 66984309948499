<template>
  <v-card>
    <v-card-title class="primary white--text">
      Производство
    </v-card-title>
    <v-card-text>
      Бла бла бла 0 руб/мес
      <div>Только для орг || Статус: вкл</div>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="success" to="manufacture">Подробнее</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
}
</script>
